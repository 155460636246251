import React from 'react';
import { Heading,Box,Text,SimpleGrid} from "@chakra-ui/react"

//import {motion} from 'framer-motion'


const Akteure =()=> {
 return(
    
    <SimpleGrid maxWidth="900" width={["100%","100%","80%","70%"]}  gap="9" pt="10" columns={[1]}>

  <Heading variant="Titel">Die Ausstellungsmacher:innen</Heading>
 <Box>

   
   <Box>
     <Heading variant="Titel">Christoph Pies &amp; Carolin Mann</Heading>
     <Text variant="solid" pt="0" pb="4">Dank Ihres Engagements konnte im Museum Simmmern eine umfangreiche Dokumentation über das 'Jüdische Leben im Hunsrück' entstehen. Die Dokumente der Ausstellung standen dem Ausstellungsparcours zur Verfügung. Zugleich haben sie sich um Realisation und Organisation des Ausstellungsparcours in Bacharach und Oberwesel verdient gemacht.
     </Text>
   </Box>
   <Box>
   <Heading variant="Titel">Dr. Walter Karbach</Heading>
  
   <Box pr="2" flex="2">
     <Text variant="solid" pt="0" pb="4">
       Der in Oberwesel geborene Dr. Walter Karbach hat sich wie kein anderer auf die Aufarbeitung des Wernerkults konzentriert, seine Entstehung, seine historischen und gesellschaftlichen Folgen dokumentiert und seine Verwurzelung bis in unsere Gegenwart analysiert und dabei ein unheuerliches Narrativ freigelegt, das in ganz Europa zum Antijudaismus beigetragen hat und einen wichtigen Baustein bildet für den Holocaust.
     </Text>
     </Box>
     
     <Box>
 <Heading variant="Titel">Fritz Stüber, René Broich, Georg Kallweit, Sahra Piller </Heading>
     <Text variant="solid" pt="0" pb="4">
       Fritz Stüber vom K.O.M organisierte u.a. die Aufstellung der vorhandenen Bruchstücke des "Roten Fensters" 
       in Bacharach.
     </Text>
     <Text variant="solid" pt="0" pb="4">René Broich vom K.O.M programmierte die Webseite des Pacours.</Text>
     <Text variant="solid" pt="0" pb="4">Der Grafiker Georg Kallweit entwarf das Plakat und den Flyer für die Ausstellung.</Text>
     <Text variant="solid" pt="0" pb="4">
       Sahra Piller organisierte die zahlreichen Online-Konferenzen zur Durchführung des Projekts.
     </Text>
    
 
 <Heading variant="Titel">Katrin Gloggengießer &amp; Prof. Dr. Susanne Enderwitz</Heading>
     <Text variant="solid" pt="0" pb="4">
     <span style={{fontWeight:500}}>Katrin Gloggengießer</span> und <span style={{fontWeight:500}}>Prof. Susanne Enderwitz</span>, 1. und 2. Vorsitzende vom K.O.M, übernahmen die Rolle des Veranstalters.<br/> Katrin Gloggengießer entwarf die zugehörige Webseite und akquirierte die Fördergelder, die die Ausstellung finanziell überhaupt erst möglich machten.
     </Text>
     
    
 </Box>
     
   </Box>
   <Box>
     <Heading variant="Titel">Die Grundlagen für die Auseinandersetzung mit der Vergangenheit in Bacharach legten: </Heading>
   <Heading variant="Titel">Peter Keber</Heading>
     <Text variant="solid" pt="0" pb="4">
       Der Bacharacher Rechtsanwalt gündete den Bau-Verein Wernerkapelle und veranlasste eine Publikation mit dem Titel "Toleranz vor Augen. Kunstprojekt in der Wernerkapelle" und realisierte mit dem Künstler Karl-Heinz Hartmann das Projekt "Das rote Fenster".
     </Text>
     <Text variant="solid" pt="0" pb="4">
     Auf 176 Seiten finden sich neben einem Aufsatz zur Entstehung der Wernerkapellen und einem historischen Überblick  14 Beiträge von erstaunlicher Qualität zum Begriff der Toleranz.
     </Text>
     <Text variant="solid" pt="0" pb="4">Die Stolpersteinverlegung und die Aktion "Das rote Fenster" waren die ersten ernst zu nehmenden Versuche sich in Bacharach der Nazi-Vergangenheit zu stellen.</Text>
     <Text variant="solid" pt="0" pb="4"><span style={{fontWeight:500}}>Der Lyriker und Schriftsteller Friedrich G. Paff</span>, dessen Onkel in Hadamar ermordet worden ist, unternahm mit dem Roman "Die Hexe von Bacharach" einen frühen literarischen Versuch.</Text>
     
 </Box>
 <Box>
 
 <Heading variant="Titel">Karl-Heinz Schleis</Heading>
     <Text variant="solid" pt="0" pb="4">
       Als ehemaliger Bürgermeister setzte er gegen erheblichen Widerstand mit der Durchführung der Verlegung der Stolpersteine ein internationales Projekt des Künstlers Gunter Demnig durch.
       Gunter Demnig, ein deutscher Künstler, verlegt seit 1966 zur Erinnerung an NS-Opfer Stolpersteine weltweit.<br/>
      Bis heute konnten noch nicht alle vom Künstler Gunter Demnig erstellten Pflastersteine  für die Stadt Bacharach verlegt werden.</Text>
    
       <Text variant="solid" pt="0" pb="4">
       Während seiner Amtszeit wurde auch "Das rote Fenster" des Künstlers Karl-Heinz Hartmann realisiert.
       Karl-Heinz Schleis dokumentierte fotografisch das Projekt und stellte seine Aufnahmen dem K.O.M zur Verfügung.
     </Text>

     
 </Box>
 <Box>
 <Heading variant="Titel">Theater Willy Praml</Heading>
     <Text variant="solid" pt="0" pb="4">
      Mit der Aufführung "Der Rabbi von Bacharach" auf dem Theaterfestival "Am Ufer der Poesie" leistet das Theater einen wichtigen Beitrag gegen das Vergessen.
     </Text>
     <Text mb="4">
     Das Festival findet in der Regel alle zwei Jahre statt.
     </Text>
     <a rel="noreferrer nofollower" target="_blank" href="https://mittelrheinfestival-poesie.com" ><Text variant="solid" pt="0" pb="4">&rarr; Zum diesjährigen Programm</Text></a>
 </Box>
 <Box>
 <Heading variant="Titel">Dr. Dagmar Aversano-Schreiber </Heading>
     <Text variant="solid" pt="0" pb="4">
     Dr. Dagmar Aversano-Schreiber, Historikerin, erforschte die Judenverfolgung in Bacharach in der Zeit des NS-Regimes. Ihre Schriften zur Geschichte der Juden in Bacharach und ihre Fotosammlung zur nationalsozialistischen Zeit stellte sie dem K.O.M zur Verfügung.
     Die Anstrengungen aller anderen Beteiligten sind unentgetlich erfolgt.
     </Text>
     
    
 </Box>

  <Box>
    <Heading variant="Titel">Der Künstler Ferdinand Fries</Heading>
    <Text variant="solid" pt="0" pb="4">Der Künstler Fries beteiligt sich mit mehreren Gemälden am Ausstellungsparcours.</Text>
    <Text variant="solid">Ferdinand Fries ist ein explizit politischer Künstler, der sich mit den Verwerfungen der Vergangenheit und Gegenwart auseinandersetzt und den Betrachter seiner figurativen Bildern nicht selten schokiert.</Text>
     
    </Box>
<Box>

    <Heading variant="Titel">Galerie Markt1</Heading>
    <Text variant="solid" pt="0" pb="4">Die Galerie Markt1, kuratiert von Katrin Gloggengießer, beteiligt sich durch einen eigenen Beitrag am Parcours.  </Text>
    
    </Box>
   
    <Heading  variant="Titel">Das K.O.M sagt Danke</Heading>
    <Text variant="solid" pt="0" pb="4">Das K.O.M  in seiner Funktion als Veranstalter dankt allen am Projekt beteiligten Akteuren. </Text>
    <Text variant="solid" pt="0" pb="4"><span style={{fontWeight:600}}>Ein besonderer Dank geht an die Förder:innen</span>, die das Projekt finanziell ermöglichten.</Text>
    </Box>
    
</SimpleGrid>
 )

}
export default Akteure